import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectError, setError } from './error-handler-slice';
import { revokeUserSession } from '../login/token-slice';
import { setSelectedCompany } from '../user/user-slice';

/**
 * Empty component that redirects to the error page when
 * an API error occurs.
 */
function ErrorRedirector() {
  const history = useHistory();
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  useEffect(() => {
    if (error?.redirectUrl) {
      history.push(error.redirectUrl);
      dispatch(setError(null));
      dispatch(revokeUserSession());
      dispatch(setSelectedCompany(null));
    }
  }, [error]);
  return null;
}

export default ErrorRedirector;
