import React from 'react';

import FooterLinksComponent from '../FooterLinks';

const Footer = () => (
  <footer>
    <section>
      <FooterLinksComponent />
    </section>
  </footer>
);

export default Footer;
