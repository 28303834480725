import { isRejectedWithValue } from '@reduxjs/toolkit';
import { setError } from './error-handler-slice';

/**
 * Redux middleware to capture all the errors from API calls
 * https://redux-toolkit.js.org/rtk-query/usage/error-handling
 */
export const errorHandlerMiddleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { dispatch } = api;
    dispatch(setError({
      status: action.payload?.status,
      data: action.payload?.data,
    }));
  }
  return next(action);
};
