import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import NotificationBannerComponent from '@pws-dev-portal/components/src/NotificationBanner';

// services
import { useGetAnnouncementsQuery } from '../services/cms';
import { selectHasClosedBanner, setHasClosedBanner } from '../features/notification-banner/notification-slice';

function NotificationBanner() {
  const dispatch = useDispatch();
  const hasClosedBanner = useSelector(selectHasClosedBanner);

  const { data: { results = [] } = {} } = useGetAnnouncementsQuery();

  const onCloseBanner = () => {
    dispatch(setHasClosedBanner(true));
  };

  return (
    <>
      {
      results && results.length > 0 && !hasClosedBanner
        && <NotificationBannerComponent
        onCloseBanner={ onCloseBanner }
        announcement={results[0]}
        />
      }
    </>
  );
}

export default NotificationBanner;
