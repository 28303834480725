import cloneDeep from 'lodash/cloneDeep';
import Constants from '../../../constants';

/**
 * Convert partnerType to partner access
 * @param {string} partnerType - "Indirect" or "Direct"
 * @returns {string} - "resellers" or "distributors"
 */
function mapPartnerType(partnerType) {
  switch (partnerType) {
    case Constants.PARTNER_TYPE.DIRECT:
      return Constants.PARTNER_ACCESS.DISTRIBUTORS;
    case Constants.PARTNER_TYPE.INDIRECT:
      return Constants.PARTNER_ACCESS.RESELLERS;
    default:
      return 'unknown';
  }
}

/**
 * Return the list of topics based on the topic's partnerAccess
 * and environment.
 * Sample topic object
 * {
 *   "id": "quote-status",
 *   "name": "Quote Status Change",
 *   "description": "sampe description",
 *   "events": {
 *     "changed": {}
 *   },
 *   "requireCountry": false,
 *   "partnerAccess": [
 *     "agents"
 *   ],
 *   "environments": [
 *     "dev",
 *     "stg",
 *   ]
 * }
 * @param {Object[]} topics - Array of topic object
 * @param {string} env - dev,stg,int,prd
 * @param {string} partnerType - Direct or Indirect
 * @param {boolean} isAgency
 * @param {boolean} isAgencyNxM
 * @param {boolean} internalCompanyFlag
 * @returns {Object[]} Array of topic objects
 */
function filterTopics(
  topics, env, partnerType, isAgency, isAgencyNxM = false, internalCompanyFlag = false,
) {
  if (!topics) return [];
  const filteredTopics = topics.filter((topic) => topic.environments.includes(env));
  const companyAccess = [];
  if (isAgency) {
    companyAccess.push(Constants.PARTNER_ACCESS.AGENTS);
  }
  if (isAgencyNxM) {
    companyAccess.push(Constants.PARTNER_ACCESS.AGENTS_NXM);
  }
  if (internalCompanyFlag) {
    companyAccess.push(Constants.PARTNER_ACCESS.INTERNAL);
  }
  const pt = mapPartnerType(partnerType);
  companyAccess.push(pt);
  return filteredTopics.filter((topic) => (
    topic.partnerAccess.some(
      (partnerAccess) => companyAccess.includes(partnerAccess),
    )
  ));
}

/**
 * Replaces the value of the key in the given JSON object
 * Example:
 *  const out = updateFieldValue({
 *    id: 'xxx-yyy-zzz',
 *    environment: 'prd',
 *  }, {
 *    environment: 'dev',
 *  });
 *  will result in
 *  {
 *    id: 'xxx-yyy-zzz',
 *    environment: 'dev',
 *  }
 * @param {Object} jsonObj
 * @param {Object} keysAndValue
 */
function updateFieldValue(jsonObj, keysAndValue) {
  const keys = Object.keys(jsonObj);
  const newJsonObj = { ...jsonObj };
  keys.forEach((key) => {
    const value = jsonObj[key];
    if (typeof value === 'object' && !Array.isArray(value)) {
      newJsonObj[key] = updateFieldValue(value, keysAndValue);
    } else {
      const newVal = keysAndValue[key];
      if (newVal) {
        newJsonObj[key] = newVal;
      }
    }
  });
  return newJsonObj;
}

/**
 * Update the example event with new values.
 * @param {Object[]} topics
 * @param {Object} keysAndValue
 */
function updateExamples(topics, keysAndValue) {
  const updated = [];
  if (!topics) return updated;
  topics.forEach((topic) => {
    const keys = Object.keys(topic.events);
    keys.forEach((key) => {
      const example = updateFieldValue(topic.events[key].example, keysAndValue);
      const newTopic = cloneDeep(topic);
      newTopic.events[key].example = example;
      updated.push(newTopic);
    });
  });
  return updated;
}

export {
  filterTopics,
  updateExamples,
  updateFieldValue,
};
