import Constants from '../../constants';

const getPartnerAccessType = (selectedCompany) => {
  if (selectedCompany) {
    const {
      partnerType,
      isAgency = false,
      isAgencyNxM = false,
    } = selectedCompany;
    const partnerAccess = [];
    if (isAgency) {
      partnerAccess.push(Constants.PARTNER_ACCESS.AGENTS);
    }
    if (isAgencyNxM) {
      partnerAccess.push(Constants.PARTNER_ACCESS.AGENTS_NXM);
    }
    if (partnerType === Constants.PARTNER_TYPE.INDIRECT) {
      partnerAccess.push(Constants.PARTNER_ACCESS.RESELLERS);
    } else if (partnerType === Constants.PARTNER_TYPE.DIRECT) {
      partnerAccess.push(Constants.PARTNER_ACCESS.DISTRIBUTORS);
    }
    return partnerAccess;
  }
  return [];
};

export { getPartnerAccessType };
