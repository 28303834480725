import React, { useState, useEffect } from 'react';
import findIndex from 'lodash/findIndex';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NavigationTabsComponent from '@pws-dev-portal/components/src/NavigationTabs';

import TabList from './navigation-tabs.json';
import { selectIsLoggedIn } from '../features/login/token-slice';
import {
  selectEnv,
  setMenuList,
  selectCompany,
  selectIsAgency,
  selectHasAcceptedTerms,
} from '../features/user/user-slice';

function NavigationTabs() {
  // selectors
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const selectedEnv = useSelector(selectEnv);
  const selectedCompany = useSelector(selectCompany);
  const isAgency = useSelector(selectIsAgency);
  const hasAcceptedTerms = useSelector(selectHasAcceptedTerms);

  // hooks
  const dispatch = useDispatch();
  const location = useLocation();

  const [tabs, setTabs] = useState(TabList);

  // Change the "my-apps" link dynamically
  // based on the user's selected company
  useEffect(() => {
    const myAppsIdx = findIndex(tabs, { mainNavCode: 'my-apps' });
    const orderingIdx = findIndex(tabs, { mainNavCode: 'ordering' });
    let linkToApps = '/select-companies';
    let subLinkToApps = TabList[myAppsIdx].subNav;

    // clone TabList
    const tabList = TabList.slice();
    if (selectedCompany && selectedEnv) {
      const { name } = selectedCompany;

      // get sub links from 'my-apps/my-apps' tab id
      const mySubAppsIdx = findIndex(subLinkToApps, { subNavCode: 'my-apps' });

      // update sub links from 'my-apps/my-apps' tab
      if (mySubAppsIdx !== -1) {
        linkToApps = `/${selectedEnv}/companies/${name}/apps`;
        subLinkToApps[mySubAppsIdx] = {
          ...subLinkToApps[mySubAppsIdx],
          linkTo: linkToApps,
        };
      }

      // get sub links from 'my-apps/test-api' tab id
      const mySubTestApiIdx = findIndex(subLinkToApps, { subNavCode: 'test-api' });

      // update sub links from 'my-apps/test-api' tab
      if (mySubTestApiIdx) {
        subLinkToApps[mySubTestApiIdx] = {
          ...subLinkToApps[mySubTestApiIdx],
          linkTo: `/${selectedEnv}/companies/${name}/test-api`,
        };
      }
    } else {
      subLinkToApps = null;
    }

    const linkToOrdering = TabList[orderingIdx];
    // if selected company is agency show quoting
    if (isAgency && isLoggedIn) {
      linkToOrdering.displayText = 'Quoting and Ordering';
    } else {
      linkToOrdering.displayText = 'Ordering';
    }
    tabList[myAppsIdx] = {
      ...TabList[myAppsIdx],
      linkTo: linkToApps,
      // hide my apps sub nvaigation if company is not yet selected
      subNav:
        (selectedCompany
          && hasAcceptedTerms
          && location.pathname !== `/${selectedEnv}/companies`)
        || (location.pathname === '/sitemap')
          ? subLinkToApps
          : [],
    };
    setTabs(tabList);
  }, [location, selectedEnv, selectedCompany, hasAcceptedTerms]);

  const onHandleMenuList = (menuList) => {
    dispatch(setMenuList(menuList));
  };

  return (
    <NavigationTabsComponent
      env={selectedEnv || 'dev'}
      tabs={tabs}
      location={location}
      loggedIn={isLoggedIn}
      selectedCompany={selectedCompany?.name}
      hasAcceptedTerms={hasAcceptedTerms}
      onTabClick={() => {}}
      onHandleMenuList={onHandleMenuList}
    />
  );
}

export default NavigationTabs;
