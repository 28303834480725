/*
 * Header Messages
 *
 * This contains all the text for the Header component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'boilerplate.components.Header';
export const general = 'boilerplate.general.pageTitles';

export default defineMessages({
  portalTitle: {
    id: `${scope}.portalTitle`,
    defaultMessage: 'Partner Developer Portal',
  },
  login: {
    id: `${general}.login`,
    defaultMessage: 'Sign in',
  },
  switchCompany: {
    id: `${general}.switchCompany`,
    defaultMessage: 'SwitchCompany',
  },
});
