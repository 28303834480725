import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Page from '@pws-dev-portal/components/src/Page';

import { useGetEnvironmentsQuery } from '../../services/dev-portal';
import { selectEnv } from '../user/user-slice';

/**
 * This component does a redirect depending on the state.
 * If the user haven't selected any environments yet, we redirect
 * to default env company list.  If the user has a selected environment
 * previously, redirect to that env's company list.
 *
 * /select-companies redirects to /[DEFAULT_ENV]/companies
 * /select-companies redirects to /[SELECTED_ENV]/companies
 *
 */
function SelectCompanies() {
  const { data: environments = [] } = useGetEnvironmentsQuery();
  const [defaultEnv, setDefaultEnv] = useState('');
  const selectedEnv = useSelector(selectEnv);

  // set the default environment
  useEffect(() => {
    const { results = [] } = environments;
    if (results.length) {
      let env = get(results, '[1].name', '');
      if (!env) {
        env = get(results, '[0].name', '');
      }
      setDefaultEnv(env);
    }
  }, [environments]);

  if (selectedEnv) {
    return <Redirect to={`/${selectedEnv}/companies`} />;
  }

  if (defaultEnv) {
    return <Redirect to={`/${defaultEnv}/companies`} />;
  }

  return (
    <Page>
      <div />
    </Page>
  );
}

export default SelectCompanies;
