import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { selectIsLoggedIn } from '../features/login/token-slice';

/**
 * A wrapper for <Route> that redirects to the login
 * screen if the user is not yet authenticated.
 */
function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const render = ({ location }) => {
    if (isLoggedIn) {
      return children;
    }
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referrer: location.pathname },
        }}
      />
    );
  };
  return <Route {...rest} render={render} />;
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default PrivateRoute;
