import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAccessToken } from './forge-api';
import { setSessionExpiry } from '../session/session-handler';

const initialState = {
  status: 'idle',
  accessToken: '',
  loggedIn: false,
  sessionExipryTime: null,
  sessionExpiryTimeInterval: null,
};

export const fetchToken = createAsyncThunk('token/fetchToken', async (code) => {
  const response = await fetchAccessToken(code);
  return response;
});

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    revokeUserSession: (state) => {
      state.status = 'idle';
      state.loggedIn = false;
      state.accessToken = '';
      state.sessionExpiryTimeInterval = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchToken.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchToken.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedIn = true;
        state.accessToken = action.payload.access_token;

        const expiryTime = action.payload.expires_in;
        state.sessionExpiryTimeInterval = expiryTime;

        const expiryTimeStamp = new Date().getTime() + expiryTime * 1000;
        state.sessionExipryTime = expiryTimeStamp;

        // update user session expiry
        setSessionExpiry({ expiry: expiryTimeStamp });
      })
      .addCase(fetchToken.rejected, (state) => {
        state.status = 'idle';
        state.loggedIn = false;
        state.accessToken = '';
      });
  },
});

// accessToken selector
export const selectAccessToken = (state) => state.token.accessToken;
// isLoggedIn selector
export const selectIsLoggedIn = (state) => state.token.loggedIn;
// session expiry time interval
export const selectSessionExpiryTimeInterval = (state) => state.token.sessionExpiryTimeInterval;
// session expiry time
export const selectSessionExpiryTime = (state) => state.token.sessionExipryTime;

// export the redux actions
export const { revokeUserSession } = tokenSlice.actions;

export default tokenSlice.reducer;
