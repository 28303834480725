import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 0px 0px inset;
`;

/* eslint-disable no-confusing-arrow */
export const TextContainer = styled.div`
  padding: 0px 16px;
  height: auto;
  &:hover {
    background: #f1f1f1;
  }
  cursor: pointer;
  transition-timing-function: ${(props) => props.isSelected
    ? 'cubic-bezier(0.2, 0, 0.8, 1)' : 'cubic-bezier(0.2, 0, 0.4, 1)'};
  transition-duration: ${(props) => (props.isSelected ? '250ms' : '187.5ms')};
`;

export const TitleWrapper = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.25;
  padding: 12px 0px;
  border-bottom: ${(props) => (props.isSelected ? 'solid black 2px' : 'none')};
  color: ${(props) => (props.isSelected ? '#000000' : '#00000099')};
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${(props) => (props.isSelected ? '#000000' : '#00000099')};
`;
