import React, { useEffect } from 'react';
import Page from '@pws-dev-portal/components/src/Page';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { sessionRedirectUrl } from '../session/session-handler';
import { fetchToken, selectIsLoggedIn } from './token-slice';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Handles redirect_uri from Forge login
 */
function Callback() {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const state = query.get('state');
  const code = query.get('code');

  // get accessToken given the authorization code
  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(fetchToken(code));
    }
  }, [isLoggedIn]);

  // get the state and route based on the state
  // then redirect only after we have the token.
  // If no referrer, redirect to select companies page
  useEffect(() => {
    if (isLoggedIn) {
      if (state) {
        history.replace(JSON.parse(state).referrer);
      } else {
        sessionRedirectUrl({ history });
      }
    }
  }, [state, isLoggedIn]);

  return (
    <Page>
      {/* TODO: Login loading animation */}
      <div />
    </Page>
  );
}

export default Callback;
