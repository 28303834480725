/*
 * FooterLinks Messages
 *
 * This contains all the text for the FooterLinks container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'boilerplate.containers.FooterLinks';

export default defineMessages({
  privacyPolicy: {
    id: `${scope}.linkCode.privacyPolicy`,
    defaultMessage: 'Privacy Policy & Cookies',
  },
  termsAndConditions: {
    id: `${scope}.linkCode.termsAndConditions`,
    defaultMessage: 'Terms & Conditions',
  },
  legalNotices: {
    id: `${scope}.linkCode.legalNotices`,
    defaultMessage: 'Legals Notices & Trademarks',
  },
  sitemap: {
    id: `${scope}.linkCode.sitemap`,
    defaultMessage: 'Sitemap',
  },
  copyright: {
    id: `${scope}.linkCode.copyright`,
    defaultMessage: '{link} All rights reserved.',
  },
});
