import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import HeaderComponent from '@pws-dev-portal/components/src/Header';

import { getAuthUrl, logout } from '../features/login/forge-api';
import { useGetProfileQuery, useGetCompanyListQuery } from '../services/dev-portal';
import { selectAccessToken, selectIsLoggedIn } from '../features/login/token-slice';
import {
  selectEnv,
  selectCompany,
  selectIsAdmin,
  setHasAcceptedTerms,
} from '../features/user/user-slice';
import { filterTopics } from '../features/settings/webhook/topics';
import { useGetTopicsQuery } from '../services/webhook-notification';

/**
 * Header container handles login and displays the Header component
 */
const Header = ({
  autoLogin = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const token = useSelector(selectAccessToken);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const selectedEnv = useSelector(selectEnv);
  const location = useLocation();
  const { pathname } = location;
  const [authUrl, setAuthUrl] = useState('');
  const [switchCompanyUrl, setSwitchCompanyUrl] = useState('/select-companies');
  const [myAppsUrl, setMyAppsUrl] = useState('/select-companies');
  const [inviteDevelopersUrl, setInviteDevelopersUrl] = useState('/select-companies');
  const [settingsUrl, setSettingsUrl] = useState('/select-companies');
  const isAdmin = useSelector(selectIsAdmin);
  const selectedCompany = useSelector(selectCompany);
  const featureFlags = useFlags();
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);

  // Get use profile only when the token is available
  const { data: userProfile, isFetching } = useGetProfileQuery(null, {
    skip: !token,
  });

  // Query company list count to show the "Switch Company" link.
  // Only query when the user has selected any environment.
  const {
    data: { results: companies = [] } = {},
  } = useGetCompanyListQuery(selectedEnv, { skip: !selectedEnv });

  // Query topics for webhook subscriptions
  // to determine if we need to show the settings menu
  const { data: topics } = useGetTopicsQuery({
    env: selectedEnv,
  }, { skip: !selectedEnv });

  // set the showSettingsMenu flag
  useEffect(() => {
    if (!featureFlags?.displayWebhookSettings) {
      setShowSettingsMenu(false);
      return;
    }
    const filteredTopics = filterTopics(
      topics?.results,
      selectedEnv,
      selectedCompany?.partnerType,
      selectedCompany?.isAgency,
      selectedCompany?.isAgencyNxM,
      selectedCompany?.internalCompanyFlag,
    );
    setShowSettingsMenu(filteredTopics.length > 0);
  }, [featureFlags, topics, selectedEnv, selectedCompany]);

  // Handle auto login by simulating the click on the login link
  // We can access the referrer from location.state.referrer
  // reference: https://reactrouter.com/web/api/Redirect
  useEffect(() => {
    if (autoLogin) {
      const { state: { referrer } = {} } = location;
      const qs = new URLSearchParams();
      if (referrer) {
        qs.append('state', JSON.stringify({ referrer }));
      }
      if (authUrl) {
        const loginUrl = `${authUrl}&${qs.toString()}`;
        window.location.href = loginUrl;
      }
    }
  });

  // Get the link to the Oxygen login.
  // This is a async function because inside it we are using
  // crypto.subtle.digest() to generate the code_challenge for PKCE
  useEffect(
    () => {
      if (!authUrl && pathname !== '/callback') {
        getAuthUrl().then((url) => {
          setAuthUrl(url);
        });
      }
    },
    [authUrl],
    pathname,
  );

  // Dynamically change the Switch Company URL
  // based on the user's selected environment
  useEffect(() => {
    if (selectedEnv) {
      setSwitchCompanyUrl(`/${selectedEnv}/companies`);
    }
  }, [selectedEnv]);

  useEffect(() => {
    if (selectedCompany) {
      setMyAppsUrl(`/${selectedEnv}/companies/${selectedCompany.name}/apps`);
      setInviteDevelopersUrl(`/${selectedEnv}/companies/${selectedCompany.name}/developers`);
      setSettingsUrl(`/${selectedEnv}/companies/${selectedCompany.name}/settings`);
    }
  }, [selectedCompany]);

  // If user not yet accept terms
  useEffect(() => {
    if (userProfile) {
      dispatch(setHasAcceptedTerms(userProfile.hasAcceptedTerms));
      if (!userProfile.hasAcceptedTerms) {
        history.push('/accept-terms-and-conditions');
      }
    }
  }, [userProfile]);

  return (
    <HeaderComponent
      userInfo={{
        ...userProfile,
        loggedIn: isLoggedIn,
        isAdmin,
      }}
      companiesCount={companies.length}
      authUrl={authUrl}
      onLogout={logout}
      selectCompaniesUrl={switchCompanyUrl}
      loading={isFetching}
      myAppsUrl={myAppsUrl}
      inviteDevelopersUrl={inviteDevelopersUrl}
      settingsUrl={settingsUrl}
      selectedCompany={selectedCompany}
      displayWebhookSettings={showSettingsMenu}
    />
  );
};

Header.propTypes = {
  autoLogin: PropTypes.bool,
};

export default Header;
