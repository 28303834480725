import axios from 'axios';
import { encode as base64encode } from 'base64-arraybuffer';
import { removeSessionKeys, stopSessionTimer } from '../session/session-handler';

import Constants from '../../constants';

let token = {
  access_token: '',
  refresh_token: '',
  expires_in: 0,
  token_type: '',
};

/**
 * Random string generator for use as code_verifier
 */
export const generateRandomString = (length) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

/**
 * Generate code_challenge for PKCE. More info in
 * https://forge.autodesk.com/en/docs/oauth/v2/tutorials/code-challenge/
 * https://datatracker.ietf.org/doc/html/rfc7636#section-4.2
 */
const generateCodeChallenge = async (codeVerifier) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);

  const digest = await window.crypto.subtle.digest('SHA-256', data);
  const base64Digest = base64encode(digest);
  return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

/**
 * Get the Oxygen Authorization URL. For more info
 * https://forge.autodesk.com/en/docs/oauth/v2/reference/http/authorize-GET/
 * This is an async function.
 */
async function getAuthUrl() {
  // Get random string as code_verifier.
  // Then save to localStorage so that on callback we can
  // use the same code_verifier to get an access_token. We need
  // to send code + code_verifier on the token endpoint
  const codeVerifier = generateRandomString(43);
  localStorage.setItem('code_verifier', codeVerifier);
  // create code_challenge
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  const qs = new URLSearchParams();
  qs.append('client_id', process.env.REACT_APP_FORGE_CLIENT_ID);
  qs.append('response_type', 'code');
  qs.append('code_challenge', codeChallenge);
  qs.append('code_challenge_method', 'S256');
  qs.append('redirect_uri', `${window.location.origin}/callback`);
  qs.append('scope', 'data:read');
  return `${Constants.FORGE_AUTHORIZE_URL}?${qs.toString()}`;
}

/**
 * Get an access token given the authorization code
 */
async function fetchAccessToken(code) {
  const codeVerifier = localStorage.getItem('code_verifier');
  localStorage.removeItem('code_verifier');
  const qs = new URLSearchParams();
  qs.append('client_id', process.env.REACT_APP_FORGE_CLIENT_ID);
  qs.append('code', code);
  qs.append('grant_type', 'authorization_code');
  qs.append('code_verifier', codeVerifier);
  qs.append('redirect_uri', `${window.location.origin}/callback`);
  const resp = await axios({
    method: 'POST',
    url: Constants.FORGE_TOKEN_URL,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: qs.toString(),
  });
  token = resp.data;
  return resp.data;
}

/**
 * @returns string
 */
function accessToken() {
  return token.access_token;
}

/**
 * Redirect to Forge logout
 */
function logout() {
  stopSessionTimer();
  removeSessionKeys();

  if (window?.location) {
    const redirect = encodeURIComponent(window.location.origin);
    window.location.href = `${Constants.FORGE_LOGOUT_URL}?post_logout_redirect_uri=${redirect}`;
  }
}

export {
  logout,
  getAuthUrl,
  accessToken,
  fetchAccessToken,
};
