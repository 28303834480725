import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import IntlWrapper from '@pws-dev-portal/components/src/IntlWrapper';
import { store } from './store';

// We need to expose the store so that we can access it in
// our unit tests
export { store };

/**
 * Parent container of <App />
 */
function AppContainer({ children }) {
  return (
    <Provider store={store}>
      <IntlWrapper>
        <BrowserRouter>
          {children}
        </BrowserRouter>
      </IntlWrapper>
    </Provider>
  );
}

AppContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default AppContainer;
