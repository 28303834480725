import enCompanyInfoList from './companyInfoList/companyInfoList_en.json';
import enCompanyDevelopersPage from './companyDevelopersPage/companyDevelopersPage_en.json';
import enDeleteAppPage from './deleteAppPage/deleteAppPage_en.json';
import enDeleteDeveloperPage from './deleteDeveloperPage/deleteDeveloperPage_en.json';
import enFaqPage from './faqPage/faqPage_en.json';
import enGeneralMessages from './general/en.json';
import enGettingStartedOverviewPage from './gettingStartedOverviewPage/getting_started_overview_page_en.json';
import enInviteDeveloperPage from './inviteDeveloperPage/inviteDeveloperPage_en.json';
import enLandingPage from './landingPage/landing_page_en.json';
import enModifyAppPage from './modifyAppPage/modifyAppPage_en.json';
import enMyAppsPage from './myAppsPage/myAppsPage_en.json';
import enRequestTsr from './requestTsr/requestTsr_en.json';
import enSelectCompanies from './selectCompanies/selectCompanies_en.json';
import enTermsAndConditionsPage from './termsAndConditionsPage/termsAndConditionsPage_en.json';
import enUserInputTypes from './userInputTypes/userInputTypes_en.json';

const enTranslationMessages = {
  ...enCompanyInfoList,
  ...enCompanyDevelopersPage,
  ...enDeleteAppPage,
  ...enDeleteDeveloperPage,
  ...enFaqPage,
  ...enGeneralMessages,
  ...enGettingStartedOverviewPage,
  ...enInviteDeveloperPage,
  ...enLandingPage,
  ...enModifyAppPage,
  ...enMyAppsPage,
  ...enRequestTsr,
  ...enSelectCompanies,
  ...enTermsAndConditionsPage,
  ...enUserInputTypes,
};

export default enTranslationMessages;
