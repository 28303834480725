import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: null,
};

export const errorHandlerSlice = createSlice({
  name: 'errorHandler',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = {
        ...action.payload,
      };

      const errorStatus = action.payload?.status;
      if (errorStatus === 403) {
        state.error = {
          ...action.payload,
          redirectUrl: '/unauthorized',
        };
      } else if (errorStatus === 404) {
        state.error = {
          ...action.payload,
          redirectUrl: '/page-not-found',
        };
      } else if (errorStatus === 500) {
        state.error = {
          ...action.payload,
          redirectUrl: '/server-error',
        };
      }
    },
  },
});

/**
 * Select 'error' from the state
 */
export const selectError = (state) => state.errorHandler.error;

// export the redux actions
export const { setError } = errorHandlerSlice.actions;

export default errorHandlerSlice.reducer;
