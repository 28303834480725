import React from 'react';
import PropTypes from 'prop-types';

const Page = ({ children }) => (
  <div className='page-outer-wrapper'>
    <div className='page-inner-wrapper container'>
      {children}
    </div>
  </div>
);

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};

export default Page;
