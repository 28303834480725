/* eslint-disable no-undef */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import profileIcon from '@hig/icons/build/svg/regular/profile.svg';

import ToggleUserOptions from '../ToggleUserOptions';

import Logo from '../images/autodesk-logo-primary-rgb-white.svg';
import messages from './messages';
import { formattedMessage } from '../utils/formattedMessage';
import { logUserAnalytics } from '../utils/analytics/analytics';

const StyledSkeleton = withStyles(() => ({
  root: {
    backgroundColor: 'white',
  },
}))(Skeleton);

/**
 * Header component displays the company logo and login/logout link
 */
const Header = (props) => {
  const {
    userInfo,
    companiesCount,
    authUrl,
    onLogout,
    selectCompaniesUrl = '/select-companies',
    loading,
    myAppsUrl,
    inviteDevelopersUrl,
    settingsUrl,
    selectedCompany,
    displayWebhookSettings,
  } = props;

  useEffect(() => {
    const menuOxygenId = userInfo?.userId || null;
    const menuLoginStatus = userInfo.loggedIn ? 'logged in' : 'not logged in';
    logUserAnalytics({
      menuOxygenId,
      menuLoginStatus,
    });
  }, [userInfo]);

  /* INDIVIDUAL HEADER LINKS */
  const switchCompanyElement = userInfo.loggedIn && companiesCount > 1 && (
    <span className="user-logged-in-wrapper">
      <span className="header-link-text switch-company-link">
        <Link to={selectCompaniesUrl} id="header-link-switchCompany">
          {formattedMessage(messages.switchCompany)}
        </Link>
      </span>
    </span>
  );

  const profileImageElement = userInfo.loggedIn && userInfo.profileImage ? (
    <span className="user-logo user-profile-image-url">
      <img src={userInfo.profileImage} alt="User Logo" />
    </span>
  ) : (
    <span className="user-logo user-profile-image-default">
      <img src={profileIcon} alt="profile-icon" width="24" height="24" />
    </span>
  );

  const loginElement = !userInfo.loggedIn && (
    <span className="header-link-text user-login-link">
      <a href={authUrl} id="header-link-login" data-wat-val="login">
        {formattedMessage(messages.login)}
      </a>
    </span>
  );

  const nameToggleElement = (
    <span className="header-link-text user-logged-in-wrapper">
      {loading ? (
        <StyledSkeleton variant="text" width={50} />
      ) : (
        <ToggleUserOptions
          userFirstName={userInfo.firstName}
          userLastName={userInfo.lastName}
          userIsAdmin={userInfo.isAdmin}
          userLogin={userInfo.loggedIn}
          myAppsUrl={myAppsUrl}
          inviteDevelopersUrl={inviteDevelopersUrl}
          settingsUrl={settingsUrl}
          selectedCompany={selectedCompany}
          displayWebhookSettings={displayWebhookSettings}
        />
      )}
    </span>
  );

  const logoutElement = userInfo.loggedIn && (
    <Link to="/logout" id="header-link-logout" onClick={onLogout}>
      <span className="header-link-text user-logout-link">Logout</span>
    </Link>
  );

  return (
    <div className="header-wrapper" data-wat-link-section="utilitynav">
      <div className="container header-content-wrapper">
        <span className="imgTitle-wrapper">
          <Link to="/" id="header-company-logo" data-wat-val="logo: adsk">
            <img src={Logo} alt="Partner Developer Portal" width="140" height="22" />
          </Link>
          <h1>{formattedMessage(messages.portalTitle)}</h1>
        </span>
        <span className="login-wrapper">
          {switchCompanyElement}
          {loading ? (
            <StyledSkeleton variant="circle" width={25} height={25} />
          ) : (
            profileImageElement
          )}
          {loginElement}
          {nameToggleElement}
          <span className="separator" />
          {logoutElement}
        </span>
      </div>
    </div>
  );
};

Header.propTypes = {
  /**
   * The user profile object.
   */
  userInfo: PropTypes.shape({
    userId: PropTypes.string,
    loggedIn: PropTypes.bool.isRequired,
    loginError: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profileImage: PropTypes.string,
    isAdmin: PropTypes.bool,
  }),
  /**
   * The number of companies the developer belongs to.
   * When the company is more than one, we will have
   * a swith company button in the Header
   */
  companiesCount: PropTypes.number.isRequired,
  /**
   * The link to the Oxygen login
   */
  authUrl: PropTypes.string.isRequired,
  /**
   * Logout function callback. This is called when the user
   * clicks the "logout" link button
   */
  onLogout: PropTypes.func.isRequired,
  /**
   * URL to go when user clicks "Select Company" link
   */
  selectCompaniesUrl: PropTypes.string,
  /**
   * Set to true to show loading indicator when fetching profile
   */
  loading: PropTypes.bool,
  myAppsUrl: PropTypes.string,
  inviteDevelopersUrl: PropTypes.string,
  settingsUrl: PropTypes.string,
  selectedCompany: PropTypes.object,
  displayWebhookSettings: PropTypes.bool,
};

export default Header;
