const constants = Object.freeze({
  /**
   * Authorization URL to get the authorization code on the callback
   */
  FORGE_AUTHORIZE_URL: 'https://developer.api.autodesk.com/authentication/v2/authorize',
  /**
   * Endpoint for exchanging auth code to access token
   */
  FORGE_TOKEN_URL: 'https://developer.api.autodesk.com/authentication/v2/token',
  /**
   * Endpoint for logout
   */
  FORGE_LOGOUT_URL: 'https://developer.api.autodesk.com/authentication/v2/logout',
  /**
   * User Roles
   */
  USER_ROLE: {
    ADMIN: 'admin',
    NON_ADMIN: 'non-admin',
  },
  /**
   * TSR Request
   */
  TSR_STATUS: {
    APPROVED: '1',
    REVOKED: '0',
    PENDING: '0.5',
  },
  /**
   * Blog types
   */
  BLOG_TYPES: {
    ARTICLES: 'articles',
    ERROR_CODES: 'errorCodesV2',
    PLACE_ORDER: 'placeOrderV2',
    BUSINESS_PROGRAM: 'businessProgram',
    COMMUNICATION_PARTNERS: 'communicationForPartners',
  },
  /**
   * List of available code languages
   */
  Language: {
    PHP: 'php',
    APEX: 'apex',
    JAVA: 'java',
    CSHARP: 'c-sharp',
    PYTHON: 'python',
    JAVASCRIPT: 'javascript',
  },
  PARTNER_TYPE: {
    INDIRECT: 'Indirect',
    DIRECT: 'Direct',
  },
  PARTNER_ACCESS: {
    RESELLERS: 'resellers',
    DISTRIBUTORS: 'distributors',
    AGENTS: 'agents',
    AGENTS_NXM: 'agents_nxm',
    INTERNAL: 'internal',
  },
  API_PRODUCT_CATEGORY: {
    SALES: 'sales',
    QUOTING: 'quoting',
    ORDERING: 'ordering',
    CUSTOMER_SUCCESS: 'customer-success',
  },
  ENVIRONMENT: {
    PRODUCTION: 'prd',
  },
  /** Test api hosts */
  TEST_API_HOST: {
    DEV: 'enterprise-api-dev.autodesk.com',
    INT: 'enterprise-api-int.autodesk.com',
    STG: 'enterprise-api-stg.autodesk.com',
  },
});

export default constants;
