import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasClosedBanner: false,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setHasClosedBanner: (state, action) => {
      state.hasClosedBanner = action.payload;
    },
  },
});

/**
 * Selector for if user already close the notification banner
 */
export const selectHasClosedBanner = (state) => state.notification.hasClosedBanner;

// export the redux actions
export const { setHasClosedBanner } = notificationSlice.actions;

export default notificationSlice.reducer;
