import React from 'react';
import { FormattedMessage } from 'react-intl';

const formattedMessage = (mesgObj) => {
  const { id, defaultMessage, description } = mesgObj;
  return (
    <span>
      <FormattedMessage
        id={id}
        defaultMessage={defaultMessage}
        description={description}
      />
    </span>
  );
};

export { formattedMessage };
