import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { cmsApi } from './services/cms';
import { devPortalApi } from './services/dev-portal';
import { webhookNotificationApi } from './services/webhook-notification';

import userReducer from './features/user/user-slice';
import tokenReducer from './features/login/token-slice';
import errorHandlerReducer from './features/error-handler/error-handler-slice';
import { errorHandlerMiddleware } from './features/error-handler/middleware';
import notificationReducer from './features/notification-banner/notification-slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    token: tokenReducer,
    errorHandler: errorHandlerReducer,
    notification: notificationReducer,
    [cmsApi.reducerPath]: cmsApi.reducer,
    [devPortalApi.reducerPath]: devPortalApi.reducer,
    [webhookNotificationApi.reducerPath]: webhookNotificationApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(devPortalApi.middleware)
    .concat(cmsApi.middleware)
    .concat(webhookNotificationApi.middleware)
    .concat(errorHandlerMiddleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
