import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  StyledLink,
  StyledContainer,
  TitleWrapper,
  TextContainer,
} from './styles';

function Tab({
  options,
  defaultValue,
  preUrlLink,
  onTabChange,
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  // selected value change
  const onChange = (val) => {
    setValue(val);
    onTabChange(val);
  };

  return (
    <>
      {options && options.length ? (
        <div>
          <StyledContainer>
            {options
              && options.length
              && options.map((item, index) => {
                const dataVal = `${preUrlLink ? `${preUrlLink}-${item}` : item}`;
                const isSelected = item === value;
                return (
                  <TextContainer
                    data-testid={`btn-menu-${index}`}
                    key={index}
                    isSelected={isSelected}
                    onClick={() => onChange(item)}
                  >
                    <TitleWrapper isSelected={isSelected}>
                      <StyledLink
                        data-wat-loc="navigation"
                        data-wat-val={dataVal}
                        isSelected={isSelected}
                      >
                        {item}
                      </StyledLink>
                    </TitleWrapper>
                  </TextContainer>
                );
              })}
          </StyledContainer>
        </div>
      ) : null}
    </>
  );
}

Tab.propTypes = {
  /**
   * List of Tabs
   */
  options: PropTypes.array.isRequired,
  /**
   * This will update selected filter
   */
  onTabChange: PropTypes.func.isRequired,
  /**
   * Set default selected option
   */
  defaultValue: PropTypes.string,
  preUrlLink: PropTypes.string,
};

export default Tab;
