/*
 * ToggleUserOptions Messages
 *
 * This contains all the text for the ToggleUserOptions component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'boilerplate.general.pageTitles';

export default defineMessages({
  myApps: {
    id: `${scope}.myApps`,
    defaultMessage: 'My Apps',
  },
  inviteDevelopers: {
    id: `${scope}.inviteDevelopers`,
    defaultMessage: 'Invite Developers',
  },
  myWorkbench: {
    id: `${scope}.myWorkbench`,
    defaultMessage: 'My Workbench',
  },
  editProfile: {
    id: `${scope}.editProfile`,
    defaultMessage: 'Edit Profile',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
});
