import { retry, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { onHandleErrorResponse } from './error-handler-util';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  // Add the forge access token in the Authorization header
  prepareHeaders: (headers, { getState }) => {
    const {
      token: { accessToken },
    } = getState();
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

/**
 * Notification service
 */
export const webhookNotificationApi = createApi({
  reducerPath: 'webhookNotification',
  tagTypes: [
    'subscriptions',
    'subscription',
    'topics',
    'deliveries',
  ],
  baseQuery: retry(async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error) {
      onHandleErrorResponse({ retry, result, dispatch: api.dispatch });
    }
    return result;
  }),

  endpoints: (builder) => ({
    // Get a list of webhook subscriptions
    getSubscriptions: builder.query({
      query: ({ env, name }) => ({ url: `/${env}/v2/companies/${encodeURIComponent(name)}/subscriptions` }),
      providesTags: ['subscriptions'],
    }),
    // Get details of the subscription info
    getSubscriptionInfo: builder.query({
      query: ({ env, name, id }) => `/${env}/v2/companies/${encodeURIComponent(name)}/subscriptions/${id}`,
      providesTags: ['subscription'],
    }),
    // Get the list of webhook topics
    getTopics: builder.query({
      query: ({ env }) => `/${env}/v2/webhook-topics`,
      providesTags: ['topics'],
    }),
    // add webhook subscription
    createSubscription: builder.mutation({
      query: ({ env, name, payload }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/subscriptions`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['subscriptions'],
    }),
    // update webhook subscription
    updateSubscription: builder.mutation({
      query: ({
        id, env, name, payload,
      }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/subscriptions/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['subscription', 'subscriptions'],
    }),
    // delete the webhook subscription
    deleteSubscription: builder.mutation({
      query: ({ env, name, id }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/subscriptions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['subscriptions'],
    }),
    // get a list of recent deliveries by subscription id
    getDeliveries: builder.query({
      query: ({ env, name, id }) => `/${env}/v2/companies/${encodeURIComponent(name)}/subscriptions/${id}/deliveries`,
      providesTags: ['deliveries'],
    }),
    // redeliver event
    redeliverEvent: builder.mutation({
      query: ({
        env, name, id, deliveryId,
      }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/subscriptions/${id}/deliveries/${deliveryId}/attempts`,
        method: 'POST',
      }),
      invalidatesTags: ['deliveries'],
    }),
    // publish test events
    publishTestEvent: builder.mutation({
      query: ({
        env, name, id, payload,
      }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/subscriptions/${id}/tests`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['deliveries'],
    }),
  }),
});

export const {
  useGetTopicsQuery,
  useGetDeliveriesQuery,
  useGetSubscriptionsQuery,
  useGetSubscriptionInfoQuery,
  // Mutations
  useRedeliverEventMutation,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  usePublishTestEventMutation,
} = webhookNotificationApi;
