import React from 'react';
import ReactDOM from 'react-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import AppContainer from './AppContainer';
import App from './App';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
  });

  ReactDOM.render(
    <React.StrictMode>
      <AppContainer>
        <LDProvider>
          <App />
        </LDProvider>
      </AppContainer>
    </React.StrictMode>,
    document.getElementById('app'),
  );
})();
