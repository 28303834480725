/* eslint-disable no-underscore-dangle */

import isEmpty from 'lodash/isEmpty';

import { USER_ROLE } from '../constants';

const captureAnalyticsChangeContext = (eventType, eventName, values) => {
  window.__analyticsChangeContext = window.__analyticsChangeContext || [];
  window.__analyticsChangeContext.push({
    event: {
      eventType,
    },
    eventData: {
      eventName,
      values,
    },
  });
};

const logUserAnalytics = ({ menuOxygenId = '', menuLoginStatus = '', userType = '' }) => {
  window.digitalData = window.digitalData || {};
  window.digitalData.user = window.digitalData.user || {};
  if (!isEmpty(userType)) {
    window.digitalData.user.userType = userType;
  }
  if (!isEmpty(menuOxygenId)) {
    window.digitalData.user.menuOxygenId = menuOxygenId;
  }
  if (!isEmpty(menuLoginStatus)) {
    window.digitalData.user.menuLoginStatus = menuLoginStatus;
  }
};

const sendUserAnalytics = (company) => {
  const { role } = company;
  let userType = USER_ROLE.NON_ADMIN;
  if (role === USER_ROLE.ADMIN) {
    userType = USER_ROLE.ADMIN;
  }
  logUserAnalytics({ userType });
};

const sendCompanyAnalytics = (company) => {
  const eventType = 'select_company';
  const eventName = 'select_company';
  const values = {
    companyName: company.name,
    companyCSN: company.csn,
    salesOrg: company.salesOrg,
    partnerType: company.partnerType,
  };
  captureAnalyticsChangeContext(eventType, eventName, values);
};

export {
  logUserAnalytics,
  sendUserAnalytics,
  sendCompanyAnalytics,
  captureAnalyticsChangeContext,
};
