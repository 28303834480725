import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import {
  createTheme,
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import 'sanitize.css/sanitize.css';
import './styles/appStyles.scss';
import enTranslationMessages from './translations/en';

const theme = createTheme({
  typography: {
    fontFamily: [
      'ArtifaktElement',
      'sans-serif',
      'Arial',
    ],
    fontSize: 14,
  },
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'pdp',
});

const IntlWrapper = (props) => {
  const { children } = props;
  return (
    <IntlProvider messages={enTranslationMessages} locale='en' defaultLocale='en'>
      <ThemeProvider theme={theme}>
        <StylesProvider generateClassName={generateClassName}>
          {children}
        </StylesProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

IntlWrapper.propTypes = {
  children: PropTypes.object.isRequired,
};

export default IntlWrapper;
