import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { formattedMessage } from '../utils/formattedMessage';
import linksData from './data/footer-links.json';
import messages from './messages';

export const FooterLinks = () => {
  let content = ['<div>Sorry, no external links found</div>'];
  if (linksData && linksData.footerLinks) {
    const linksInfo = linksData.footerLinks;
    content = (
      <div className="links-wrapper">
        <div className="site-info-links footer-sections footer-links-section">
          <a href={linksInfo.privacyPolicy} id="footerLinks-link-privacyPolicy">
            {formattedMessage(messages.privacyPolicy)}
          </a>
          <a
            href={linksInfo.termsAndConditions}
            id="footerLinks-link-termsAndConditions"
          >
            {formattedMessage(messages.termsAndConditions)}
          </a>
          <a href={linksInfo.legalNotices} id="footerLinks-link-legalNotices">
            {formattedMessage(messages.legalNotices)}
          </a>
          <Link to={linksInfo.sitemap} id="footerLinks-link-sitemap">
            {formattedMessage(messages.sitemap)}
          </Link>
        </div>
        <div className="copy-right-link footer-sections center-align">
          <span>
            <FormattedMessage
              id={messages.copyright.id}
              defaultMessage={messages.copyright.defaultMessage}
              description={messages.copyright.description}
              values={{
                link: (
                  <a href={linksInfo.copyright} id="footerLinks-link-copyright">
                    Autodesk Inc
                  </a>
                ),
              }}
            />
          </span>
        </div>
      </div>
    );
  }

  return <div>{content}</div>;
};

export default FooterLinks;
