import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import Banner from '@hig/banner';
import PropTypes from 'prop-types';

const MAX_INT32 = 2147483647;

/**
 * NotificationBanner component for showing banner for announcements
 * triggered from PIC portal
 */
const NotificationBanner = ({
  onCloseBanner,
  announcement,
}) => {
  const [showBanner, setShowBanner] = useState(false);

  /*
   * Check whether to show banner based on server response
   */
  useEffect(() => {
    const mesg = get(announcement, 'message', null);
    const expires = get(announcement, 'expires', 0);
    if (mesg && mesg.length > 0) {
      setShowBanner(true);

      /* create timer for the expiry */
      const now = Math.floor(Date.now() / 1000);
      if (now < expires) {
        const toClear = (expires - now) * 1000;
        if (toClear < MAX_INT32) {
          // setTimeout only accepts max 32bit integer
          // https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
          setTimeout(() => {
            setShowBanner(false);
          }, toClear);
        }
      } else if (expires > 0) {
        setShowBanner(false);
      }
    } else {
      setShowBanner(false);
    }
  }, [announcement]);

  /* Banner close button click */
  const handleBannerClose = () => {
    setShowBanner(false);
    onCloseBanner();
  };

  return (
    showBanner && (
      <Banner type="urgent" onDismiss={handleBannerClose}>
        {announcement.message}
      </Banner>
    )
  );
};

NotificationBanner.propTypes = {
  onCloseBanner: PropTypes.func.isRequired,
  announcement: PropTypes.object,
};

export default NotificationBanner;
