import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledLinearProgress = withStyles({
  root: {
    height: 3,
  },
  colorPrimary: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  barColorPrimary: {
    backgroundColor: 'black',
  },
})(LinearProgress);

/**
 * Linear progress indicator
 */
function Progress() {
  return (
    <StyledLinearProgress
      color='primary'
    />
  );
}

export default Progress;
